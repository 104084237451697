.contact {
    min-height: 40vh;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 60px;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

.h1 {
    font-family: "Manrope";
    font-weight: 700;
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
}

.main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .contact {
        min-height: 60vh;
        margin-right: 148px;
        margin-left: 148px;
        padding-top: 112px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box; /* Opera/IE 8+ */
    }

    .h1 {
        font-family: "Manrope";
        font-weight: 700;
        font-size: 30px;
        margin-right: auto;
        margin-left: auto;
    }

    .main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
