.aboutme {
    min-height: 100vh;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 60px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

.h1 {
    font-family: "Manrope";
    font-weight: 700;
    font-size: 30px;
    margin-right: auto;
}

.aboutMeTopSection {
    margin-bottom: 64px;
    margin-right: 32px;
    margin-left: 32px;
}

.aboutMidSection {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 64px;
}
.elementContainer {
    display: flex;
    align-items: center;
    height: 72px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 16px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

.iconContainer {
    padding-left: 20px;
    padding-right: 20px;
    border-right: 1px solid black;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.elementTextContainer {
    padding-left: 16px;
}
.elementH1 {
    font-family: "Manrope";
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 6px;
}

.elementText {
    font-family: "Manrope";
    font-weight: 400;
    font-size: 12px;
}

.aboutBottomSection {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.aboutBottomElement {
    min-height: 257px;
    background-color: #fff;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 16px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}
.aboutBottomList {
}
.aboutBottomListHeader {
    color: #000;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.aboutBottomListItem {
    color: #000;
    font-family: "Manrope";
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .aboutme {
        margin-right: 120px;
        margin-left: 120px;
        padding-top: 112px;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .aboutMidSection {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 64px;
    }

    .elementContainer {
        display: flex;
        align-items: center;
        height: 72px;
        width: 334px;
        border-radius: 10px;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
    }

    .aboutBottomSection {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .aboutBottomElement {
        width: 334px;

        min-height: 257px;
        background-color: #fff;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .aboutme {
        min-height: 75vh;
        margin-right: 148px;
        margin-left: 148px;
        padding-top: 112px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box; /* Opera/IE 8+ */
    }

    .h1 {
        font-family: "Manrope";
        font-weight: 700;
        font-size: 30px;
        margin-right: auto;
    }

    .main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .aboutMeTopSection {
        margin-bottom: 64px;
        padding-left: 17.5%;
        padding-right: 17.5%;
    }

    .aboutMidSection {
        display: flex;
        justify-content: space-between;
        margin-bottom: 64px;
    }
    .elementContainer {
        display: flex;
        align-items: center;
        height: 72px;
        width: 334px;
        border-radius: 10px;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
    }

    .iconContainer {
        padding-left: 20px;
        padding-right: 20px;
        border-right: 1px solid black;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .elementTextContainer {
        padding-left: 16px;
    }
    .elementH1 {
        font-family: "Manrope";
        font-weight: 900;
        font-size: 20px;
        margin-bottom: 6px;
    }

    .elementText {
        font-family: "Manrope";
        font-weight: 400;
        font-size: 12px;
    }

    .aboutBottomSection {
        display: flex;

        justify-content: space-between;
    }
    .aboutBottomElement {
        width: 334px;

        min-height: 257px;
        background-color: #fff;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px;
    }
    .aboutBottomList {
    }
    .aboutBottomListHeader {
        color: #000;
        font-family: "Manrope";
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
    .aboutBottomListItem {
        color: #000;
        font-family: "Manrope";
        font-size: 14px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
    }
}
