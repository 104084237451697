.topSection {
    height: 80px;
    display: flex;
    margin-left: 20px;
    position: absolute;
}

.text {
    margin-top: 40px;
    font-family: "Manrope";
    font-weight: 900;
    font-size: 24px;
}

.headerMain {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100%);
}

.headerInputs {
}
.headerMain p {
    font-family: "DotGothic16";
    font-size: 20px;
}
.headerInputs input {
    position: relative;
    background-color: rgba(0, 0, 0, 1);
    font-family: "DotGothic16";
    font-size: 20px;
    padding-right: 10px;
    border: none;
    text-align: right;
    line-height: 12px;
    max-width: 20ch;
    z-index: 1;
    transition: background-color 0.5s ease-in-out;
}
.headerInputs label {
    position: absolute;
    font-family: "DotGothic16";
    font-size: 20px;
    margin-left: -10px;
    line-height: 12px;
    z-index: 12;

    border-bottom: 3px solid white;
    padding-top: 26px;
    width: 12px;
}

.headerInputs label:hover {
    cursor: text;
}

.headerMain input:focus {
    outline: none;
}

.nav {
    display: none;
    position: fixed;
    transform: rotate(-90deg);
    justify-content: center;
    align-items: center;
    margin-top: 50vh;
    margin-left: -112px;
}

.nav ul {
    display: flex;
    flex-direction: row-reverse;
}

.nav ul li {
    font-family: "Manrope";
    font-size: 16px;
    font-weight: 200;
    margin-right: 25px;
    color: white;
}

.nav ul li:hover {
    opacity: 0.8;
    cursor: pointer;
}

.darkModeButtonContainer {
    width: 100px;

    position: absolute;
    transform: rotate(-90deg);
    margin-right: -20px;
    right: 10px;
    bottom: 75px;
}
.darkModeText:hover {
    cursor: pointer;
}
.darkModeText {
    font-family: "Manrope";
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.blink {
    animation: blinker 0.9s step-start infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .topSection {
        height: 80px;
        display: flex;
        margin-left: 80px;
        position: absolute;
    }

    .text {
        margin-top: 40px;
        font-family: "Manrope";
        font-weight: 900;
        font-size: 24px;
    }

    .headerMain {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100%);
    }

    .headerMain p {
        font-family: "DotGothic16";
        font-size: 30px;
    }

    .headerInputs input {
        position: relative;
        background-color: rgba(0, 0, 0, 1);
        font-family: "DotGothic16";
        font-size: 30px;
        padding-right: 15px;
        border: none;
        text-align: right;
        line-height: 24px;
        max-width: 24ch;
    }
    .headerInputs label {
        position: absolute;
        font-family: "DotGothic16";
        font-size: 30px;
        margin-left: -15px;
        line-height: 24px;
        padding-top: 38px;
        width: 16px;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .topSection {
        height: 80px;
        display: flex;
        margin-left: 148px;
        position: absolute;
    }

    .text {
        margin-top: auto;
        font-family: "Manrope";
        font-weight: 900;
        font-size: 36px;
        line-height: 36px;
    }

    .headerMain {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100%);
    }

    .headerMain p {
        font-family: "DotGothic16";
        font-size: 30px;
    }

    .nav {
        position: fixed;
        transform: rotate(-90deg);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50vh;
        margin-left: -112px;
    }

    .nav ul {
        display: flex;
        flex-direction: row-reverse;
    }

    .nav ul li {
        font-family: "Manrope";
        font-size: 16px;
        font-weight: 200;
        margin-right: 25px;
        color: white;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .headerMain p {
        font-family: "DotGothic16";
        font-size: 45px;
    }

    .headerInputs input {
        position: relative;
        background-color: rgba(0, 0, 0, 1);
        font-family: "DotGothic16";
        font-size: 45px;
        padding-right: 24px;
        border: none;
        text-align: right;
        line-height: 45px;
        max-width: 26ch;
    }
    .headerInputs label {
        position: absolute;
        font-family: "DotGothic16";
        font-size: 45px;
        margin-left: -24px;
        line-height: 45px;

        padding-top: 55px;
        width: 24px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .topSection {
        height: 80px;
        display: flex;
        margin-left: 148px;
        position: absolute;
    }

    .text {
        margin-top: auto;
        font-family: "Manrope";
        font-weight: 900;
        font-size: 36px;
        line-height: 36px;
    }

    .headerMain {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100%);
    }

    /* .headerInputs p {
        font-family: "DotGothic16";
        font-size: 45px;
    }
    .headerInputs input {
        position: relative;
        font-size: 45px;
        padding-right: 20px;
    }
    .headerInputs label {
        position: absolute;
        font-family: "DotGothic16";
        font-size: 45px;
        padding-top: 10px;
        margin-left: -20;
        line-height: 1px;
    } */

    .nav {
        position: fixed;
        transform: rotate(-90deg);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50vh;
        margin-left: -112px;
    }

    .nav ul {
        display: flex;
        flex-direction: row-reverse;
    }

    .nav ul li {
        font-family: "Manrope";
        font-size: 16px;
        font-weight: 200;
        margin-right: ;
        color: white;
    }
}
