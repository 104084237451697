.main {
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
    margin-right: 16px;
    margin-left: 16px;
}

.imageSection {
    width: 100%;
    height: 233px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageContainer {
}

.image {
    width: 270px;
}

.infoSection {
    display: flex;

    flex-direction: column;
    height: 233px;
    width: 276px;
}

.infoTopContainer {
    display: flex;
    align-items: flex-end;
    margin-top: 12px;
    margin-bottom: 16px;
}

.infoH1 {
    font-family: "Manrope";
    font-weight: 900;
    font-size: 20px;
}

.infoH2 {
    font-family: "Manrope";
    font-weight: 400;
    font-size: 12px;
    margin-left: 8px;
}

.infoText {
    font-family: "Manrope";
    font-weight: 300;
    font-size: 12px;
}

.tech {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.techElement {
    margin-right: 6px;
    margin-top: auto;
    font-size: 12px;
    font-family: "Manrope";
    font-weight: 900;
}

.linkContainer {
    display: flex;
    margin-top: 12px;

    margin-bottom: 12px;
}

.linkContainer a {
    font-family: "Manrope";
    font-size: 16px;
    font-weight: 600;
}
.linkContainer a:hover {
    cursor: pointer;
}
a,
a:visited,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .main {
        margin-right: 14px;
        margin-left: 14px;
    }
    .imageSection {
        width: 233px;
    }
    .image {
        width: 212px;
    }
    .infoSection {
        width: 233px;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 850px) {
    .main {
        display: flex;
        flex-direction: row;
        margin-right: 4px;
        margin-left: 4px;
        margin-bottom: 24px;
    }

    .infoSection {
        display: flex;

        flex-direction: column;
        height: 233px;
        width: 240px;
        margin-left: 10px;
    }

    .imageSection {
        height: 233px;
        width: 233px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imageContainer {
    }
    .image {
        width: 212;
    }

    .infoTopContainer {
        display: flex;
        align-items: flex-end;
        margin-top: 12px;
        margin-bottom: 16px;
    }

    .infoH1 {
        font-family: "Manrope";
        font-weight: 900;
        font-size: 20px;
    }

    .infoH2 {
        font-family: "Manrope";
        font-weight: 400;
        font-size: 12px;
        margin-left: 8px;
    }

    .infoText {
        font-family: "Manrope";
        font-weight: 300;
        font-size: 12px;
    }

    .tech {
        margin-top: auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .techElement {
        font-size: 12px;
        font-family: "Manrope";
        font-weight: 900;
    }

    .linkContainer {
        display: flex;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .linkContainer a {
        font-family: "Manrope";
        font-size: 16px;
        font-weight: 600;
    }
}
